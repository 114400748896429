<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios'
import Swal from "sweetalert2";

export default {
    components: {
        Layout,
        PageHeader,
    },
    data(){
        return {
            title: "Admin",
            email: '',
            password: '',
            newpassword: '',
            name : '',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            users:[],
            isDisable: false,
            submitted : false,
            showmodal : false,
            showmodal1 : false,
            showPassword: false,
            fields: [
                { key: 'index', label:'No' },
                { key: "email", label:"Email" },
                { key: "name", label:"Name" },
                { key: "created_at", label:"Create At" },
                { key: "last_login", label:"last_login" },
                { key: "status", label:"Status" },
                { key: "reset", label: "Reset Password"},
                { key: "onOff", label: "Enable/Disable"},
            ],
            data: [],
            isBusy: false,
        }
    },
    computed: {
    rows() {
      return this.users.length;
    },
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  mounted() {
    this.totalRows = this.users.length;
  },
  methods: {
    onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
    getAdmin(){
      this.isBusy = true;
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-admin/${id}`)
        .then((res) => {
            this.isBusy = false;
            this.users = res.data.account
        })
    },
    hideModal() {
        this.submitted = false;
        this.name = '',
        this.password = '',
        this.email = '',
        this.showmodal = false;
    },
    hideModal1() {
        this.submitted = false;
        this.newpassword = '';
        this.showmodal1 = false;
    },
    handleSubmit(){
        this.submitted = true;
        this.isDisable = true;
        let id  = JSON.parse(localStorage.getItem("user")).user_id;
        let data = {
            email : this.email,
            name : this.name,
            password : this.password,
        }
        axios.post(`v1/add-admin/${id}`, data)
        .then((res) => {
            if(res.data.success){
                Swal.fire("Success", "Admin was created", "success");
                this.getAdmin();
                this.showmodal = false;
                this.submitted = false;
                this.isDisable = false;
            } else {
                Swal.fire('Error', res.data.message, "error");
                this.isDisable = false;
                this.submitted = false;
            }
        })
    },
    reset(e){
       this.showmodal1 = true;
       this.data = e.item;
    },
    handleSubmit2(){
        this.submitted = true;
        this.isDisable = true;
        let id = this.data.id
        let data = {
            password : this.newpassword
        }
        axios.post(`v1/password-reset/${id}`, data)
        .then((res) => {
            if(res.data.success){
                Swal.fire("Success", "Password was reset", "success");
                this.getAdmin();
                this.showmodal1 = false;
                this.submitted = false;
                this.isDisable = false;
            } else {
                Swal.fire('Error', "Failed reset", "error");
                this.isDisable = false;
            }
        })
    },
    enable(e){
        let id = e.item.id
        let status = e.item.status 
        if(status == 1) {
            axios.post(`/v1/account-enable/${id}/1`)
            .then(() => {
                this.getAdmin();
            })
        } else {
            axios.post(`/v1/account-enable/${id}/0`)
            .then(() => {
                this.getAdmin();
            })
        }
    }
  },
  created () {
    this.getAdmin();
  },
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                <div class="card-body">
                    <div>
                    <a href="javascript:void(0);" class="btn btn-primary mb-2" @click="showmodal = true">
                        <i class="mdi mdi-plus mr-2"></i> Add Admin
                    </a>
                    </div>
                    <div class="row">
                    <div class="col-12">
                        <div class="">
                        <div class="card-body">
                            <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    Show&nbsp;
                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                    v-model="filter"
                                    type="search"
                                    placeholder="Search..."
                                    class="form-control form-control-sm ml-2"
                                    ></b-form-input>
                                </label>
                                </div>
                            </div>
                            <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive mb-0">
                            <b-table
                                :items="users"
                                :fields="fields"
                                striped
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                :busy="isBusy"
                                show-empty
                            >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(status)="row">
                                <div
                                    class="badge font-size-12"
                                    :class="{'badge-soft-danger': `${row.item.status}` == 1,
                                        'badge-soft-success': `${row.item.status}` != 1}"
                                >{{ row.item.status == 1 ? 'Disabled' : 'Active'}}</div>
                                </template>
                                <template v-slot:cell(admin)="row">
                                    <div
                                    >{{row.item.admin_name}}</div>
                                </template>
                                <template v-slot:cell(reset)="row">
                                    <button @click="reset(row)" class="btn btn-primary btn-rounded">
                                    <i class="mdi mdi-lock-reset"></i>
                                    </button>
                                </template>
                                <!-- <template v-slot:cell(onOff)="row">
                                    <button @click="enable(row)" :class="['btn', 'btn-rounded',
                                  {'btn-success': `${row.item.status}` != 1,
                                    'btn-danger' : `${row.item.status}` == 1}]">
                                    <i class="ri ri-shut-down-line"></i>
                                    </button>
                                </template> -->
                                <template v-slot:cell(onOff)="row">
                                    <b-form-checkbox value="0" unchecked-value="1" v-model="row.item.status" switch @change="enable(row)"></b-form-checkbox>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Loading...</strong>
                                    </div>
                                </template>
                                <template #empty>
                                    <h5 class='empty'>No Admin Data ...</h5>
                                </template>
                            </b-table>
                            </div>
                            <!-- <template v-else>
                            <h5 class='empty'>No Admin Data ...</h5>
                            </template> -->
                            <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <b-modal
            id="modal"
            v-model="showmodal"
            title="Add Admin"
            title-class="text-dark font-18"
            scrollable
            hide-footer
            centered
            size="lg"
            @show="hideModal"
            @hidden="hideModal"
            >
            <form @submit.prevent="handleSubmit">
                <template>
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-4 mt-2">
                            <h5 for="name">Nama</h5>
                            </div>
                            <div class="col-8">
                            <input
                                id="name"
                                v-model="name"
                                type="text"
                                class="form-control"  
                                placeholder="Enter Name"
                                required
                            />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 mt-2">
                            <h5 for="emails">Email</h5>
                            </div>
                            <div class="col-8">
                            <input
                                id="emails"
                                v-model="email"
                                type="email"
                                class="form-control"  
                                placeholder="Enter Email"
                                autocomplete="off"
                                required
                            />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 mt-2">
                            <h5 for="passwords">Password</h5>
                            </div>
                            <div class="col-8">
                            <input
                                id="passwords"
                                v-model="password"
                                type="password"
                                class="form-control"  
                                placeholder="Enter Password"
                                autocomplete="off"
                                required
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <b-button variant="secondary" @click="hideModal">Cancel</b-button>
                   <button type="submit" class="btn btn-success ml-1" style="width:5rem" :disabled="isDisable">
                        <span v-if="submitted"><b-spinner class="p-0" style="width:20px;height:20px" variant="black" role="status"></b-spinner></span>
                        <span v-else>Submit</span>
                    </button>
                </div>
                </template>
            </form>
        </b-modal>
        <!-- end modal -->
        <b-modal
            id="modal"
            v-model="showmodal1"
            title="Reset Password"
            title-class="text-dark font-18"
            scrollable
            hide-footer
            centered
            size="md"
            @show="hideModal1"
            @hidden="hideModal1"
            >
            <form @submit.prevent="handleSubmit2">
                <template>
                <div class="card">
                    <div class="card-item">
                        <div class="p-4">
                            <div class="form-group auth-form-group-custom">
                                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                <label for="userpassword">Reset Password</label>
                                <input
                                    v-model="newpassword"
                                    password-reveal
                                    :type="showPassword ? 'text' : 'password'"
                                    class="form-control"
                                    id="password"
                                    placeholder="Enter New Password"
                                    autocomplete="off"
                                    required
                                />
                                <a class="auti-custom-input-icon2" @mousedown="showPassword = !showPassword" @mouseup="showPassword = !showPassword" @touchstart="showPassword = !showPassword" @touchend="showPassword = !showPassword">
                                <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <b-button variant="secondary" @click="hideModal1">Cancel</b-button>
                    <button type="submit" class="btn btn-success ml-1" style="width:5rem" :disabled="isDisable">
                        <span v-if="submitted"><b-spinner class="p-0" style="width:20px;height:20px" variant="black" role="status"></b-spinner></span>
                        <span v-else>Submit</span>
                    </button>
                </div>
                </template>
            </form>
        </b-modal>
    </Layout>
</template>